<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset
            id="promotionInfo"
            :title="$t('Promotion Information')"
          >
            <b-form-group
              :label="`*`+$t('Promotion name')+`:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Promotion name"
                rules="required"
              >
                <b-form-input
                  v-model="model.name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Promotion name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row class="align-items-center">
              <b-col md="4">
                {{ $t("Promotion's Time") }}
              </b-col>
              <b-col>
                <label
                  for="date-from"
                  class="d-block"
                >
                  <flat-pickr
                    v-model="model.date_from"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      minDate: 'today',
                      dateFormat: 'Y-m-d H:i:ss',
                    }"
                    placeholder="From"
                  />
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="DateFrom"
                  rules="required"
                >
                  <input
                    id="date-from"
                    v-model="model.date_from"
                    type="hidden"
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col>
                <label
                  for="date-to"
                  class="d-block"
                >
                  <flat-pickr
                    v-model="model.date_to"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      minDate: 'today',
                      dateFormat: 'Y-m-d H:i:ss',
                    }"
                    placeholder="To"
                  />
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="DateTo"
                  rules="required"
                >
                  <input
                    id="date-to"
                    v-model="model.date_to"
                    type="hidden"
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-form-group
              class="mb-0 mt-1"
              :label="$t('Active')+`:`"
              label-cols-md="2"
            >
              <b-form-checkbox
                v-model="model.is_active"
                checked="true"
                class="custom-control-success d-flex align-items-center"
                name="check-button"
                switch
              />
            </b-form-group>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div>
            <Fieldset
              id="avatar"
              :title="$t('Avatar')"
            >
              <FeatureImage
                :avatar="model.avatar"
                style="max-width: 177px"
                @previewFiles="previewFiles"
              />
            </Fieldset>
          </div>
        </b-col>
        <b-col md="12">
          <Fieldset
            id="promotionRole"
            :title="$t('Advanced Option')"
          >
            <div
              class="d-flex flex-wrap justify-content-between mb-2"
              style="gap: 8px;"
            >
              <h4>{{ $t('Promotion product') }}</h4>
              <b-button
                v-if="model.status == 'Upcoming'"
                v-b-modal.modal-xl
                :disabled="checkDate()"
                variant="primary"
                type="button"
                class="ml-3"
              >
                {{ $t('Add product') }}
              </b-button>
            </div>

            <!-- Advance -->
            <div
              v-if="model.status == 'Upcoming'"
              class="advance-block mb-2"
            >
              <b-row>
                <b-col
                  lg="3"
                  class="d-flex flex-column justify-content-center"
                >
                  {{ $t('Batch setting') }}
                  <div><strong>{{ model.selected.length }}</strong>&nbsp;{{ $t('selected products') }}</div>
                </b-col>
                <b-col style="max-width: 125px;">
                  <b-form-group
                    :label="$t('Discount amount')"
                  >
                    <b-input-group
                      append="%"
                      class="mx-auto"
                    >
                      <b-form-input
                        v-model="limitOn.percent"
                        type="number"
                        min="1"
                        max="100"
                        class="text-right"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label="$t('Number of promotional products')"
                    label-for="basicInput"
                    class="limit-form-group"
                  >
                    <b-form-select
                      v-model="limitOn.promotion"
                      :options="limitProductsOptions"
                    />
                    <b-form-input
                      v-model="limitOn.promotionQuantity"
                      type="number"
                      min="1"
                      :placeholder="`${ $t('Minimum at') } 1`"
                      :disabled="!limitOn.promotion"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label="$t('Limited products per person')"
                    label-for="basicInput"
                    class="limit-form-group"
                  >
                    <b-form-select
                      v-model="limitOn.person"
                      :options="limitProductsOptions"
                    />
                    <b-form-input
                      v-model="limitOn.personQuantity"
                      type="number"
                      min="1"
                      :placeholder="`${ $t('Minimum at') } 1`"
                      :disabled="!limitOn.person"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  lg="2"
                  md="6"
                  class="d-flex flex-column justify-content-center"
                  style="gap: 8px;"
                >
                  <b-button
                    variant="gradient-success"
                    size="sm"
                    pill
                    class="w-100"
                    @click="advanceApply"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Apply') }}</span>
                  </b-button>

                  <b-button
                    variant="gradient-danger"
                    size="sm"
                    pill
                    class="w-100"
                    :disabled="model.selected.length === 0"
                    @click="advanceDelete"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Delete') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <PromotionItems v-model="model" />
          </Fieldset>
          <PopupEditProduct
            v-if="model.id_promotion"
            v-model="model"
          />
        </b-col>
        <b-col
          v-if="model.status == 'Upcoming'"
          md="12"
        >
          <BCard>
            <b-button
              variant="info"
              type="button"
              @click.prevent="validationForm"
            >
              {{ $t('Update') }}
            </b-button>
          </BCard>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BFormSelect,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupEditProduct from './components/PopupEditProduct.vue'
import PromotionItems from './components/PromotionItems.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    flatPickr,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PopupEditProduct,
    BInputGroup,
    BFormSelect,
    PromotionItems,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: this.avatarDefault(),
        name: null,
        percent: null,
        is_active: true,
        date_from_to: null,
        date_from: null,
        date_to: null,
        list_product: [],
        list_product_old: [],
        json_product: [],
        content: null,
        apply_all_product: 0,
        selectedAll: false,
        selected: [],
        validCheckAll: 0,
        id_promotion: 0,
        status: '',
      },
      fields: [
        // { key: 'checkbox', label: '#', sortable: true },
        { key: 'no', label: 'No', sortable: true },
        { key: 'avatar', label: 'Avatar', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'sku', label: 'SKU', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'more', label: 'More', sortable: true },
      ],
      columns: [
        {
          label: 'Product',
          field: 'fullName',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Discount price',
          field: 'priceDiscount',
          thClass: 'text-left',
        },
        {
          label: 'Inventory',
          field: 'inventory',
        },
        {
          label: 'Limits',
          field: 'productsOnSale',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      pageLength: 10,
      searchTerm: '',
      limitProductsOptions: [
        {
          value: null,
          text: 'Không giới hạn',
        },
        {
          value: 'limit',
          text: 'Giới hạn',
        },
      ],
      // limitProductsOnPromotion: null,
      // limitProductsOnPerson: null,
      promotionProductsSelected: 0,
      promotionProductsSelectedCount: 0,
      limitOn: {
        promotion: null,
        person: null,
        promotionQuantity: null,
        personQuantity: null,
        percent: null,
      },
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    // 'model.list_product'(val) {
    // },
  },
  created() {
    // this.$http.get('/good-table/basic')
    //   .then(res => { this.rows = res.data })
    this.loadDetail()
  },
  methods: {
    generalListProd() {
      // const temp = [...new Set(val)]
      this.model.list_product = []
      // this.model.list_product = temp
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/v2/promotion/${this.$route.params.id}?site_id=${siteId}`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.model = Object.assign(this.model, res.data.data)
          this.model.date_from_to = `${res.data.data.date_from} to ${res.data.data.date_to}`
          this.model.date_from = `${res.data.data.date_from}`
          this.model.date_to = `${res.data.data.date_to}`
          this.model.is_active = Boolean(res.data.data.is_active === 1)
          this.model.id_promotion = `${res.data.data.id_number}`
          this.model.list_product_old = res.data.data.products
          // this.model.json_product = JSON.parse(res.data.data.json_product)
          this.model.status = res.data.data.status
        }
      }
    },
    checkDate() {
      // let bloon = true
      // if (this.model.date_from != null && this.model.date_to != null) {
      //   bloon = false
      // }
      // return bloon
      return false
    },
    async validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              date_from: this.model.date_from,
              date_to: this.model.date_to,
              content: 'Test',
              apply_all_product: this.model.apply_all_product,
              type: 0,
            }
            if (this.model.date_from !== null) {
              params.date_from = this.model.date_from
            }
            if (this.model.date_to !== null) {
              params.date_to = this.model.date_to
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            if (this.model.apply_all_product === 0 && this.model.list_product && this.model.list_product.length > 0) {
              params.list_id_product = []
              this.model.list_product.map(item => {
                params.list_id_product.push(item.id)
                return true
              })
            }

            const listIdProduct = []
            const listPromotionWarehouse = []
            let numEr = 0
            this.model.list_product.map((val, index) => {
              const findData = this.model.list_product[index]
              findData.children.map((val2, index2) => {
                const temp = this.model.list_product[index].children[index2]
                if (val2.valid.status === true) {
                  const findData2 = listIdProduct.find(x => x.id_number === val.id)
                  if (!findData2) {
                    listIdProduct.push({
                      id_number: val.id,
                      limit_buy: Number(val.limitProducts),
                    })
                  }
                  listPromotionWarehouse.push({
                    warehouse_id: val2.id,
                    id: val2.id_warehouse_promotion,
                    limit_quantity: val2.is_limit_warehouse === 'limit' ? val2.limit_warehouse : 0,
                    percent: val2.percent,
                    price_new: val2.discount,
                    is_active: val2.status ? 1 : 0,
                  })
                  if (Number(temp.percent) === 0 || temp.percent > 100 || temp.percent < 0) {
                    temp.valid.ok_percent = 'error'
                    numEr += 1
                    temp.valid.show = true
                    temp.valid.status = false
                    temp.status = false
                  } else {
                    temp.valid.ok_percent = ''
                  }
                } else {
                  numEr += 1
                }
                return true
              })
              return true
            })

            if (numEr !== 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('Invalid product list'),
                },
              })
              return false
            }
            params.list_id_product = JSON.stringify(listIdProduct)
            params.list_promotion_warehouse = JSON.stringify(listPromotionWarehouse)

            if (listPromotionWarehouse.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('No products selected yet'),
                },
              })
              return false
            }

            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/promotion/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                const delList = []
                this.model.list_product_old.map(val => {
                  const findProdDel = listIdProduct.find(x => x.id_number === val.id)
                  if (!findProdDel) {
                    delList.push(val.id)
                  }
                  return true
                })
                const delParams = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  product_id: JSON.stringify(delList),
                  promotion_id: this.model.id_promotion,
                }
                await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/v2/promotion/delete_product_from_promotion`,
                  delParams,
                )
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Promotion success',
                  },
                })
                this.$router.push('/promotion/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String('Form validate not success'),
            },
          })
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    advanceApply() {
      if (this.model.selected.length > 0) {
        this.model.selected.map(val => {
          const findData = this.model.list_product.find(x => x.id === val)
          if (findData) {
            findData.limitProducts = this.limitOn.personQuantity
            findData.isLimitProduct = this.limitOn.person
            findData.children.map((val2, index) => {
              findData.children[index].percent = this.limitOn.percent
              findData.children[index].discount = findData.children[index].price - ((findData.children[index].price / 100) * this.limitOn.percent)
              findData.children[index].limit_warehouse = this.limitOn.promotionQuantity
              findData.children[index].is_limit_warehouse = this.limitOn.promotion
              return true
            })
          }
          return true
        })
      } else {
        this.model.list_product.map((val, index) => {
          const findData = this.model.list_product[index]
          findData.limitProducts = this.limitOn.personQuantity
          findData.isLimitProduct = this.limitOn.person
          findData.children.map((val2, index2) => {
            findData.children[index2].percent = this.limitOn.percent
            findData.children[index2].discount = findData.children[index2].price - ((findData.children[index2].price / 100) * this.limitOn.percent)
            findData.children[index2].limit_warehouse = this.limitOn.promotionQuantity
            findData.children[index2].is_limit_warehouse = this.limitOn.promotion
            return true
          })
          return true
        })
      }
      this.model.validCheckAll = Math.random()
    },
    advanceDelete() {
      const temp = JSON.parse(JSON.stringify(this.model.selected))
      temp.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
        return true
      })
      this.model.selected = []
      this.model.selectedAll = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
  }
  td {
    vertical-align: middle;
  }
  .input-group {
    width: 165px;
    max-width: 150px;
  }
  .form-group {
    width: 165px;
  }
  .limit-form-group {
    max-width: 180px;

    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
  .avatar-name {
    display: flex;
    align-items: center;
    min-width: 200px;

    .name {
      max-width: 180px;
    }
  }
}
.advance-block {
  background: #F6F6F6;
  padding: 1rem;

  .form-group {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
  .form-group {
    height: 100%;

    > div {
      margin-top: auto;
    }
  }
  .limit-form-group {
    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
</style>
